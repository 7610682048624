import React from "react"
import * as styles from "./image-gallery.module.scss"

export default function ImageGalleryArrow(props) {

  let arrowClass = props.reverseOrientation
    ? `${styles.arrow} ${styles.left}`
    : `${styles.arrow} ${styles.right}`

  let iconClass = props.reverseOrientation
    ? `icon-chevron-left-icon ${styles.left}`
    : `icon-chevron-right-icon ${styles.right}`;

  return <>
    <span className={arrowClass} onClick={props.onClick}>
      <span className={`${iconClass} ${styles.icon}`} />
    </span>
  </>
}
