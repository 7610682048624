import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import ImageDescriptionWithBackground
    from "../../components/imageDescrpitionWithBackground/image-description-with-background";

export default function DessertSection() {

    const data = useStaticQuery(graphql`{
    imageDesktop: file(relativePath: {eq: "beefteka/beers.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
  }
  `);
    return <ImageDescriptionWithBackground data={data} header={"Nasze piwa produkowane są na miejscu"}
                                        text={"W Beeftece jesteśmy dumni z tego, że oferujemy piwa pod marką Browar Koło. Przyjdź do naszej restauracji i zobacz jak powstaje oraz jak smakuje piwo z Koła!"}
                                        buttonText={"Dowiedz się więcej"}
                                        buttonTarget={"https://browarkolo.pl"}
                                        useAnchor={true} />

}