import React from "react"
import * as styles from "./image-gallery.module.scss"
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";

export default function ImageGalleryItem(props) {
  return <div style={props.style}>
    <a href={props.href} className={styles.carouselItem}>
      <div className={styles.backgroundImageOuterWrapper}>
        <GatsbyImage
          image={props.imgFluid}
          alt={props.imgAlt}
          className={styles.backgroundImageInnerWrapper} />
      </div>
      <div className={styles.textWrapper}>
        <Container fluid>
          <Row className="no-gutters align-items-center">
            <Col lg={{ span: 10, offset: 1 }} md={{ span: 12, offset: 0 }} xs={12}>
              <div className={styles.textHeader}>
                {props.textHeader}
              </div>
              <div className={styles.textDescription}>
                {props.textDescription}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </a>
  </div>;
}
