import React from "react"
import * as styles from "./central-header.module.scss"
import {Container, Row, Col} from "react-bootstrap";

export default function CentralHeaderRow(props) {
    return <Container fluid>
        <Row>
            <Col md={{span: 11, offset: 1}} xs={12}>
                <div className={styles.container}>
                    <div className={styles.subheader}>{props.subheader}</div>
                    <div className={styles.header}>{props.header}</div>
                </div>
            </Col>
        </Row>
    </Container>
}
