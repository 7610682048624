import React from "react"
import * as styles from "./image-gallery.module.scss"
import ImageGalleryArrow from './image-gallery-arrow';
import Slider from "react-slick";

export default function ImageGallery(props) {

  const settings = {
    dots: false,
    speed: 500,

    rows: 2,
    slidesPerRow: 3,
    
    nextArrow: <ImageGalleryArrow />,
    prevArrow: <ImageGalleryArrow reverseOrientation />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          rows: 2,
          slidesPerRow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          rows: 3,
          slidesPerRow: 2,
          dots: false,

          nextArrow: <></>,
          prevArrow: <></>,
        }
      }
    ]
  };

  return <>
    <div className={styles.wrapper}>
      <Slider
        {...settings}
      >
        {props.children}
      </Slider>
    </div>
  </>
}
