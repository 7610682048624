import React from "react"
import * as styles from "./image-description-with-background.module.scss"
import {Row, Col} from "react-bootstrap";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import {GatsbyImage} from "gatsby-plugin-image";
import HorizontalLineSeparatorMobile
    from "../../components/horizontal-line-separator-mobile/horizontal-line-separator-mobile";
import NavigationButtonOrange from "../navigation-button/navigation-button-orange";


export default function ImageDescriptionWithBackground(props) {

    const data = props.data;

    return <>
            <VerticalSpacingRow/>
            <Row noGutters>
                <Col xl={{ span: 6, offset: 0, order: 2 }} lg={{span: 4, offset: 0, order: 2}} sm={{span: 12, order: 1}}>
                    <div className={styles.imageWrapper}>
                    <GatsbyImage
                        image={data.imageDesktop.childImageSharp.gatsbyImageData}
                        alt={"picture"}
                        objectFit="cover"
                        className={styles.photoWrapper}/>
                    </div>
                </Col>

                <Col xl={{ span: 4, offset: 1, order: 1 }} lg={{span: 6, offset: 1, order: 1}} sm={{span: 12, order: 2}}>
                    <div className={styles.colWrapper}>
                        <Col md={{span: 10, offset: 1, order: 1}} className={styles.colWrapper} >
                            <div className={styles.textWrapper}>
                                <div className={styles.header}>{props.header}</div>
                                <div className={styles.text}>
                                {Array.isArray(props.text)
                                    ? props.text.map((t, i) => <p key={i}>{t}</p>)
                                    : <>{props.text}</>}
                                </div>
                                <div className={styles.buttonWrapper}>
                                    <NavigationButtonOrange buttonTarget={props.buttonTarget}
                                                      buttonText={props.buttonText}
                                                      useAnchor={props.useAnchor}
                                                        />
                                </div>
                            </div>
                        </Col>
                    </div>
                </Col>
            </Row>
            <HorizontalLineSeparatorMobile/>
    </>
        ;


}