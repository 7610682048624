// extracted by mini-css-extract-plugin
export var arrow = "image-gallery-module--arrow--7y35y";
export var backgroundImageInnerWrapper = "image-gallery-module--background-image-inner-wrapper--LpeuK";
export var backgroundImageOuterWrapper = "image-gallery-module--background-image-outer-wrapper--0ZazF";
export var carouselItem = "image-gallery-module--carousel-item--05ObR";
export var icon = "image-gallery-module--icon--lF4Ds";
export var left = "image-gallery-module--left--Lda6i";
export var right = "image-gallery-module--right--3bwM5";
export var textHeader = "image-gallery-module--text-header--3m1wj";
export var textWrapper = "image-gallery-module--text-wrapper--xt-z8";
export var wrapper = "image-gallery-module--wrapper--A3lTs";